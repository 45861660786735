import { AutocompleteItem, LinkType, State, StringLink } from "@/store";
import { Store } from "vuex";

export function getUserLink(token: string, store: Store<State>): StringLink|null {
  if (token.length === 0) {
    return null;
  }

  const match = token.toLowerCase();
  const all = store.state.users.values();

  for (let user of all) {
    if (user.name.toLowerCase() === match) {
      return {
        content: '@' + user.name,
        link: {
          href: user.dbid,
          type: LinkType.USER
        }
      }
    }
  }

  return null;
}

export function queryUsers(token: string, store: Store<State>): Array<AutocompleteItem> {
  if (token === null) {
    return [];
  }

  const match = token.toLowerCase();
  const all = store.state.users.values();
  const result: Array<AutocompleteItem> = [];

  for (let user of all) {
    if (token.length === 0 || user.name.toLowerCase().startsWith(match)) {
      result.push({
        label: user.name,
        id: user.dbid,
        type: LinkType.USER,
        replacement: user.name
      });
    }
  }

  return result;
}
