import { AutocompleteItem, LinkType, State, StringLink } from "@/store";
import { Store } from "vuex";

export function getCenterLink(token: string, store: Store<State>): StringLink|null {
  if (token.length === 0 || !/^\d+$/.test(token)) {
    return null;
  }

  const match = parseInt(token, 10);
  const center = store.state.all.get(match);

  if (center) {
    return {
      content: '@' + token,
      link: {
        type: LinkType.COST_CENTER,
        href: match
      }
    };
  }

  return null;
}

export function queryCenters(token: string, store: Store<State>): Array<AutocompleteItem> {
  if (token === null || token.length < 1) {
    return [];
  }

  const match = token;
  const all = store.state.all.values();
  const result: Array<AutocompleteItem> = [];

  for (let center of all) {
    if (center.dbid.toString().startsWith(match)) {
      if (match.length === 1 && center.dbid.toString().length > 2) {
        // When entering '1' don't return all matches between 100 and 200 for example
        continue;
      }
      result.push({
        label: `${center.dbid} ${center.title}`,
        id: center.dbid,
        type: LinkType.COST_CENTER,
        replacement: center.dbid.toString()
      });
    }
  }

  return result;
}
