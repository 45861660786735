import { vModelText as _vModelText, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["maxlength", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppEditorPanel = _resolveComponent("AppEditorPanel")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.wrapperClass)
    }, [
      _withDirectives(_createElementVNode("textarea", {
        class: _normalizeClass(_ctx.taclass),
        ref: "appTextarea",
        maxlength: _ctx.maxLength,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.text) = $event)),
          _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.emit && _ctx.emit(...args)))
        ],
        onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.enterKey($event)), ["enter"])),
        id: _ctx.taid
      }, null, 42, _hoisted_1), [
        [_vModelText, _ctx.modelValue.text]
      ])
    ], 2),
    _createElementVNode("div", {
      style: _normalizeStyle({ top: `${_ctx.getPanelTop()}px`, left: `${_ctx.getPanelLeft()}px` }),
      class: "panel-position"
    }, [
      _createVNode(_component_AppEditorPanel, {
        autocomplete: _ctx.autocomplete,
        collections: _ctx.collections,
        isOpen: _ctx.isOpen,
        status: _ctx.status,
        activeId: _ctx.activeItemId
      }, null, 8, ["autocomplete", "collections", "isOpen", "status", "activeId"])
    ], 4)
  ]))
}