import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_tooltip = _resolveComponent("o-tooltip")!

  return (_openBlock(), _createBlock(_component_o_tooltip, {
    triggers: ['click', 'hover'],
    variant: "info",
    label: _ctx.dtTmFormat
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(["tt-link", { 'item-highlight': _ctx.highlight }])
      }, _toDisplayString(_ctx.time), 3)
    ]),
    _: 1
  }, 8, ["label"]))
}