
import { StringLink, Task } from "@/store";
import { Options, Vue } from "vue-class-component";
import InfoTask from "../infoitems/InfoTask.vue";

@Options({
  props: {
    task: Object
  },
  components: {
    InfoTask
  }
})
export default class AppTaskPreview extends Vue {
  task!: Task;
  currentIdx: number = null;
  currentInfo: StringLink = null;

  showInfo(info: { event: MouseEvent, item: StringLink }, idx: number): void {
    this.currentInfo = info.item;
    this.currentIdx = idx;
  }
}
