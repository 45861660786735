import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppEditor = _resolveComponent("AppEditor")!
  const _component_o_tab_item = _resolveComponent("o-tab-item")!
  const _component_AppTaskPreview = _resolveComponent("AppTaskPreview")!
  const _component_o_tabs = _resolveComponent("o-tabs")!

  return (_openBlock(), _createBlock(_component_o_tabs, {
    modelValue: _ctx.activeTab,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeTab) = $event)),
    expanded: false,
    size: "large",
    position: "centered",
    animated: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_o_tab_item, {
        value: 0,
        label: "Edit"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_AppEditor, {
              modelValue: _ctx.task,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.task) = $event)),
              costCenterId: _ctx.costCenterId
            }, null, 8, ["modelValue", "costCenterId"])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_o_tab_item, {
        value: 1,
        label: "Preview"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_AppTaskPreview, { task: _ctx.task }, null, 8, ["task"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}