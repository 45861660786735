
import { StringLink } from '@/store';
import { Options, Vue } from 'vue-class-component';
import StringLinkInfo from './StringLinkInfo.vue';

@Options({
  props: {
    list: Array,
    highlight: Array
  },
  components: {
    StringLinkInfo
  },
  emits: ['linkClick']
})
export default class StringLinkList extends Vue {
  list!: Array<StringLink|string>;
  highlight!: Array<string>;

  doLinkClick(event: MouseEvent, item: StringLink): void {
    this.$emit('linkClick', { event, item });
  }

  isHighlight(item: StringLink): boolean {
    return this.highlight.includes(`${item.link.href}`);
  }
}
