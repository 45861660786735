import { AutocompleteItem, LinkType, State, StringLink, Task } from "@/store";
import { Store } from "vuex";

export function getTaskLink(token: string, store: Store<State>): StringLink|null {
  if (token.length === 0 || !/^\d+\-T\d+$/.test(token)) {
    return null;
  }

  let task: Task = null;
  store.state.tasks.forEach(tsk => {
    if (tsk.refid === token) {
      task = tsk;
    }
  });

  if (task) {
    return {
      content: '@' + task.refid,
      link: {
        type: LinkType.TASK,
        href: task.dbid,
        ccid: task.ccid
      }
    };
  }

  return null;
}

export function queryTasks(token: string, store: Store<State>): Array<AutocompleteItem> {
  if (token === null) {
    return [];
  }

  const match = token;
  const all = store.state.tasks.values();
  const result: Array<AutocompleteItem> = [];

  for (let task of all) {
    if (token.length === 0 || task.refid.startsWith(match)) {
      result.push({
        label: `${task.refid} ${task.title}`,
        id: task.dbid,
        type: LinkType.TASK,
        replacement: task.refid
      });
    }
  }

  return result;
}
