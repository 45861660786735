
import { BaseItem } from "@algolia/autocomplete-core";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    hit: Object
  }
})
export default class AppEditorUser extends Vue {
  hit!: BaseItem;
}
