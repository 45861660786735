
import { DbId, MjCostCenter } from '@/dto/types';
import { findMentions } from '@/helpers/mentions';
import { LinkType, Priority } from '@/store';
import { Options, Vue } from 'vue-class-component';
import IconCheckbox from './IconCheckbox.vue';

@Options({
  props: {
    center: Object,
    pinned: Boolean,
    removing: Boolean
  },
  components: {
    IconCheckbox
  },
  emits: ['pinToggled', 'showMore']
})
export default class CostCenterSummary extends Vue {
  center!: MjCostCenter;
  removing = false;

  get related(): { standard: number, urgent: number, mentions: number } {
    const tasks = this.$store.state.tasks.values();
    const ret = { standard: 0, urgent: 0, mentions: 0 };

    for (let tsk of tasks) {
      if (tsk.ccid === this.center.dbid) {
        if (tsk.priority === Priority.URGENT) {
          ret.urgent++;
        } else if (tsk.priority === Priority.STANDARD) {
          ret.standard++;
        }
      }
    }

    ret.mentions = findMentions(this.$store, LinkType.COST_CENTER, this.center.dbid).size;

    return ret;
  }

  togglePin(dbid: DbId, pinState: boolean): void {
    this.$emit('pinToggled', { dbid, pinState });
  }

  showMore(dbid: DbId) {
    this.$emit('showMore', dbid);
  }
}
