import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-646f153e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "material-icons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "app-badge-wrapper",
    title: _ctx.badge.title
  }, [
    _createElementVNode("span", {
      class: _normalizeClass([_ctx.badge.clazz, "history-badge"])
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.badge.icon), 1)
    ], 2)
  ], 8, _hoisted_1))
}