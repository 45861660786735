import { State, StringLink } from "@/store";
import { Store } from "vuex";
import { getCenterLink } from "../autocomplete/sources/centers";
import { getTaskLink } from "../autocomplete/sources/tasks";
import { getUserLink } from "../autocomplete/sources/users";
import { getDateLink } from "../autocomplete/sources/dates";
import { getPhotoLink } from "../autocomplete/sources/photos";

function findToken(elem: string): string {
  const terminating = '!?.,%:\r\n\t ';
  for (let i = 0; i < elem.length; i++) {
    const ch = elem.charAt(i);
    if (terminating.indexOf(ch) >= 0) {
      return elem.substr(0, i);
    }
  }
  return elem;
}

export function parsePreview(line: string, store: Store<State>): (StringLink|string)[] {
  const elems = line.split('@');
  const result = [];

  let first = true;

  for (let elem of elems) {
    const token = findToken(elem);
    let processed = false;

    for (let method of [getUserLink, getCenterLink, getDateLink, getTaskLink, getPhotoLink]) {
      const link = method(token, store);
      if (link !== null) {
        result.push(link);
        processed = true;
        break;
      }
    }

    if (!processed) {
      result.push(first ? token : '@' + token);
    }

    result.push(elem.substr(token.length));

    first = false;
  }

  return result;
}
