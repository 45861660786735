
import { dtTmFormat } from "@/helpers/util";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    dt: Date,
    highlight: Boolean
  }
})
export default class AppTime extends Vue {
  dt!: Date;
  highlight!: boolean;

  get time(): string {
    return this.dt.toLocaleTimeString();
  }

  get dtTmFormat(): string {
    return dtTmFormat(this.dt);
  }
}
