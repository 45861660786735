
import { Options, Vue } from 'vue-class-component';
import { DbId, MjCostCenter } from '@/dto/types';
import CostCenterSummary from './CostCenterSummary.vue';
import QuickHistoryList from './QuickHistoryList.vue';

@Options({
  props: {
  },
  components: {
    CostCenterSummary,
    QuickHistoryList
  }
})
export default class CostCenterAllList extends Vue {
  openDbid: DbId|null = null;

  refresh() {
  }

  mounted() {
    this.refresh();
  }

  isPinned(dbid: DbId): boolean {
    return this.$store.state.pinned.has(dbid);
  }

  get centers(): Array<MjCostCenter> {
    return Array.from(this.$store.state.all.values());
  }

  get storePinned(): Array<MjCostCenter> {
    return Array.from(this.$store.state.pinned).map(dbid => this.$store.state.all.get(dbid));
  }

  togglePin(dbid: DbId, pinned: boolean): void {
    if (!pinned) {
      this.$store.commit('removePinned', dbid);
    } else {
      this.$store.commit('addPinned', dbid);
    }
  }

  expand(dbid: DbId): void {
    if (this.openDbid === dbid) {
      this.openDbid = null;
    } else {
      this.openDbid = dbid;
    }
  }
}
