
import { Vue } from "vue-class-component";
import { DbId } from "./dto/types";
import { formatTimestamp, fromTimestamp } from "./helpers/util";
import { HistoryItem, State } from "./store";

export default class App extends Vue {
  cd = new Date();
  lastSaved: Date = null;
  islogged = false;
  email = '';
  password = '';
  failed = false;

  get loading(): boolean {
    return this.$store.state.loading;
  }

  set loading(newval: boolean) {
    this.$store.commit('loading', newval);
  }

  login() {
    fetch('/secret-login.php', {
      method: 'POST',
      body: JSON.stringify({ email: this.email, password: this.password })
    })
    .then(res => res.json())
    .then(res => {
      if (!res.result) {
        this.failed = true;
        this.loading = false;
        this.islogged = false;
        return;
      }
      this.islogged = true;
      this.loadData();
    });
  }

  formatDates<T extends { created?: Date }>(items: Map<DbId, T>): Array<Omit<T, 'created'> & { created: string }> {
    return Array.from(items.values())
      .map(item => ({ ...item, created: formatTimestamp(item.created) }));
  }

  loadData() {
    fetch('/get.php')
      .then(res => res.json())
      .then(json => this.convert(json))
      .then(json => {
        this.$store.commit('load', json);
        this.islogged = true;
        this.loading = false;
        this.failed = false;
      }); 
  }

  beforeCreate() {
    if (this.$store.state.all.size === 0) {
      fetch('/islogged.php')
        .then(res => res.json())
        .then(res => {
          if (res.result) {
            this.loadData();
          } else {
            this.islogged = false;
            this.loading = false;
          }
        });
    }
  }

  createMap<T extends { dbid: number}>(items: Array<T>): Map<DbId, T> {
    const ret = new Map<DbId, T>();
    for (let item of items) {
      ret.set(item.dbid, item);
    }
    return ret;
  }

  createHistoryMap(from: ReturnType<typeof App.prototype.createSave>['history']): Map<DbId, HistoryItem[]> {
    const ret = new Map<DbId, HistoryItem[]>();
    for (let item of from) {
      const actions = item.actions.map(act => ({ ...act, timestamp: this.parseTimestamp(act.timestamp) }));
      ret.set(item.ccid, actions);
    }
    return ret;
  }
  

  parseTimestamp(ts?: string|Date): Date|undefined {
    if (typeof ts === 'string') {
      return fromTimestamp(ts);
    } else if (typeof ts === 'object') {
      return ts;
    } else {
      return undefined;
    }
  }

  convert(json: ReturnType<typeof App.prototype.createSave>): Omit<State, 'loading'> {
    return {
      all: this.createMap(json.centers),
      pinned: new Set(json.pinned),
      tasks: this.createMap(json.tasks.map(tsk => ({ ...tsk, created: this.parseTimestamp(tsk.created), updated: this.parseTimestamp(tsk.updated) }))),
      history: this.createHistoryMap(json.history),
      users: this.createMap(json.users.map(usr => ({ ...usr, created: this.parseTimestamp(usr.created) }))),
      photos: this.createMap(json.photos.map(pht => ({ ...pht, created: this.parseTimestamp(pht.created) })))
    };
  }

  createSave(s: State) {
    return {
      centers: Array.from(s.all.values()),
      pinned: Array.from(s.pinned.values()),
      tasks: this.formatDates(s.tasks).map(tsk => { return tsk.updated ? ({ ...tsk, updated: formatTimestamp(tsk.updated) }) : tsk; }),
      photos: this.formatDates(s.photos),
      users: this.formatDates(s.users),
      history: Array
        .from(s.history.entries())
        .map(item => (
        {
          ccid: item[0],
          actions: item[1].map(action => ({ ...action, timestamp: formatTimestamp(action.timestamp) }))
        }))
        .filter(history => history.actions.length > 0)
    }
  }

  save(): void {
    const s = this.$store.state;
    const data = this.createSave(s);
    this.loading = true;

    fetch('/save.php', {
      method: 'POST',
      body: JSON.stringify(data)
    })
    .then(_ => this.lastSaved = new Date())
    .then(_ => {
      this.loadData();
    });
  }
}
