import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74e2430d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tt-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_tooltip = _resolveComponent("o-tooltip")!

  return (_openBlock(), _createBlock(_component_o_tooltip, {
    triggers: ['click', 'hover'],
    variant: "info",
    label: _ctx.dtTmFormat
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.timeAgo), 1)
    ]),
    _: 1
  }, 8, ["label"]))
}