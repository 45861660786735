
import { Options, Vue } from 'vue-class-component';
import { DbId, MjCostCenter } from '@/dto/types';
import CostCenterSummary from './CostCenterSummary.vue';
import QuickHistoryList from './QuickHistoryList.vue';

@Options({
  props: {
  },
  components: {
    CostCenterSummary,
    QuickHistoryList
  }
})
export default class CostCenterPinnedList extends Vue {
  openDbid: DbId|null = null;
  pinRemoved = new Set<DbId>();

  refresh(): void {
    this.pinRemoved.clear();
  }

  isPinned(dbid: DbId): boolean {
    return this.$store.state.pinned.has(dbid);
  }

  get centers(): Array<MjCostCenter> {
    return Array.from(this.$store.state.all.values());
  }

  get storePinned(): Array<MjCostCenter> {
    return Array.from(this.$store.state.all.values())
             .filter(cc => this.$store.state.pinned.has(cc.dbid) || this.pinRemoved.has(cc.dbid));
  }

  togglePin(dbid: DbId, pinned: boolean): void {
    if (!pinned) {
      this.pinRemoved.add(dbid);
      this.$store.commit('removePinned', dbid);
    } else {
      this.pinRemoved.delete(dbid);
      this.$store.commit('addPinned', dbid);
    }
  }

  expand(dbid: DbId): void {
    if (this.openDbid === dbid) {
      this.openDbid = null;
    } else {
      this.openDbid = dbid;
    }
  }
}
