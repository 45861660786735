
import { DbId } from "@/dto/types";
import { dtFormat, dtTmFormat, timeAgo } from "@/helpers/util";
import { LinkType, StringLink } from "@/store";
import { Options, Vue } from "vue-class-component";
import { durationDescription, parseAuDate, toLongFormat } from "./autocomplete/sources/dates";
import InfoTask from "./infoitems/InfoTask.vue";
import StringLinkList from "./StringLinkList.vue";
import { parsePreview } from "./taskpreview/parsePreview";

@Options({
  props: {
    str: Object,
    highlight: Array
  },
  components: {
    StringLinkList,
    InfoTask
  },
  emits: ['itemClick']
})
export default class StringLinkInfo extends Vue {
  str!: StringLink;
  highlight!: Array<string>;

  doSubItemClick(info: { item: StringLink, event: MouseEvent }) {
    this.$emit('itemClick', info);
  }

  split(str: string): string[] {
    return str.split('\n');
  }

  processLine(line: string) {
    return parsePreview(line, this.$store);
  }

  dtTmFormat(date: Date): string {
    return dtTmFormat(date);
  }

  timeAgo(date: Date): string {
    return timeAgo(date);
  }

  dtFormat(date: Date): string {
    return dtFormat(date);
  }

  getLongDate(item: StringLink): string {
    const parsed = parseAuDate(item.link.href as string, 'ymd');
    const dt = new Date(parsed.vYear, parsed.vMonth - 1, parsed.vDate);
    return toLongFormat(dt);
  }

  getDuration(item: StringLink): string {
    const parsed = parseAuDate(item.link.href as string, 'ymd');
    const dt = new Date(parsed.vYear, parsed.vMonth - 1, parsed.vDate);
    return durationDescription(dt);
  }

  isDate(type: LinkType): boolean {
    return type === LinkType.DATE;
  }

  isCenter(type: LinkType): boolean {
    return type === LinkType.COST_CENTER;
  }

  getCenter(item: StringLink) {
    return this.$store.state.all.get(item.link.href as DbId);
  }

  isUser(type: LinkType): boolean {
    return type === LinkType.USER;
  }
  
  getUser(item: StringLink) {
    return this.$store.state.users.get(item.link.href as DbId);
  }

  isTask(type: LinkType): boolean {
    return type === LinkType.TASK;
  }

  getTask(item: StringLink) {
    return this.$store.state.tasks.get(item.link.href as DbId);
  }

  isPhoto(type: LinkType): boolean {
    return type === LinkType.PHOTO;
  }

  getPhoto(item: StringLink) {
    return this.$store.state.photos.get(item.link.href as DbId);
  }
}
