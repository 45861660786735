import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32175a6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "icon-checkbox__input" }
const _hoisted_3 = ["checked"]
const _hoisted_4 = {
  class: "icon-checkbox__control",
  "aria-hidden": "true",
  focusable: "false"
}
const _hoisted_5 = { class: "material-icons" }
const _hoisted_6 = { class: "icon-checkbox__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "",
    style: _normalizeStyle({ color: _ctx.color, 'font-size': `${_ctx.size}px` })
  }, [
    _createElementVNode("label", {
      class: "icon-checkbox",
      title: _ctx.tooltip
    }, [
      _createElementVNode("span", _hoisted_2, [
        _createElementVNode("input", {
          type: "checkbox",
          name: "",
          value: "",
          checked: _ctx.modelValue,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.update($event)))
        }, null, 8, _hoisted_3),
        _createElementVNode("span", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.icon), 1)
        ])
      ]),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.title), 1)
    ], 8, _hoisted_1)
  ], 4))
}