
import { AutocompleteItem } from "@/store";
import { AutocompletePropGetters, GetItemProps } from "@algolia/autocomplete-core";
import { Options, Vue } from "vue-class-component";
import AppEditorUser from "./AppEditorUser.vue";

@Options({
  props: {
    itemProps: Object,
    item: Object
  },
  components: {
    AppEditorUser
  }
})
export default class AppEditorItem extends Vue {
  itemProps!: GetItemProps<AutocompleteItem, MouseEvent>;
  item!: AutocompleteItem;

  mounted() {
    this.$watch('itemProps', () => {
      const div = this.$refs.autoDiv as HTMLElement;
      for (let key of Object.keys(this.itemProps)) {
        if (!key.startsWith('on')) {
          div.setAttribute(key, this.itemProps[key]);
        }
      }
    });
  }
}
