
import AppBadge from "@/components/AppBadge.vue";
import AppDateTime from "@/components/AppDateTime.vue";
import StringLinkInfo from "@/components/StringLinkInfo.vue";
import StringLinkList from "@/components/StringLinkList.vue";
import { parsePreview } from "@/components/taskpreview/parsePreview";
import { HistoryItem, StringLink } from "@/store";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    AppBadge,
    AppDateTime,
    StringLinkList,
    StringLinkInfo
  }
})
export default class Recent extends Vue {
  currentInfo: StringLink = null;
  currentIdx: number = null;

  get history(): Array<HistoryItem> {
    const hitems = Array.from(this.$store.state.history.values()).flat();
    hitems.sort((a, b) => (+b.timestamp) - (+a.timestamp));
    return hitems;
  }

  showInfo(info: { event: MouseEvent, item: StringLink }, idx: number): void {
    this.currentInfo = info.item;
    this.currentIdx = idx;
  }

  processLine(line: string) {
    return parsePreview(line, this.$store);
  }
}
