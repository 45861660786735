import { days, toAuDtFormat } from "@/helpers/util";
import { AutocompleteItem, LinkType, State, StringLink } from "@/store";
import { Store } from "vuex";

export function durationDescription(dt: Date) {
  const today = new Date();
  let diff = ((+dt) - (+today)) / 1000;
  const dayDuration = 3600 * 24;
  let suffix = '';

  if (dt.getTimezoneOffset() !== today.getTimezoneOffset()) {
    diff -= (dt.getTimezoneOffset() - today.getTimezoneOffset()) * 60;
  }

  if (today.getFullYear() === dt.getFullYear() && today.getMonth() === dt.getMonth() && today.getDate() === dt.getDate()) {
    suffix = '(today)';
  } else if (diff > 0 && diff < (2 * dayDuration) && ((today.getDay() + 1) % 7) === dt.getDay()) {
    suffix = '(tomorrow)';
  } else if (diff < 0 && Math.abs(diff) < (2 * dayDuration) && ((dt.getDay() + 1) % 7) == today.getDay()) {
    suffix = '(yesterday)';
  } else if (diff > 0) {
    suffix = `(in ${Math.ceil(diff / dayDuration)} days)`;
  } else if (diff < 0) {
    suffix = `(${Math.trunc(Math.abs(diff) / dayDuration)} days ago)`;
  }

  return suffix;
}

function formatReplacement(dt: Date): string {
  return toAuDtFormat(dt);
}

const dtFormatter = new Intl.DateTimeFormat('en-au', { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' });

export function toLongFormat(dt: Date): string {
  return dtFormatter.format(dt);
}

export function parseAuDate(auDate: string, order: 'ymd'|'dmy'): { vDate: number, vMonth: number, vYear: number }|null {
  const parts = auDate.split('/');

  if (parts.length === 3 && parts.every(part => /^(\d+)$/.test(part))) {
    const parsed = parts.map(part => parseInt(part, 10));
    if (order === 'ymd') {
      var [vYear, vMonth, vDate] = parsed;
    } else {
      var [vDate, vMonth, vYear] = parsed;
    }
    if (vDate <= 31 && vDate >= 1 && vMonth <= 12 && vMonth >= 1 && vYear >= 1900 && vYear <= 2100) {
      return { vDate, vMonth, vYear };
    }
  }

  return null;
}

/**
 * If the token is in the format Sunday-25/08/2020 (as returned from toAuDtFormat in
 * util.ts) turns it into a StringLink, otherwise null.
 */
export function getDateLink(token: string, store: Store<State>): StringLink|null {
  for (let day of days) {
    if (token.startsWith(day + '-')) {
      const auDate = token.substr(day.length + 1);
      const possibleDt = parseAuDate(auDate, 'dmy');

      if (possibleDt) {
        const { vYear, vMonth, vDate } = possibleDt;
        return {
          content: '@' + token,
          link: {
            type: LinkType.DATE,
            href: `${vYear}/${vMonth}/${vDate}`
          }
        };
      }

      break;
    }
  }

  return null;
}

export function queryDates(token: string, store: Store<State>): Array<AutocompleteItem> {
  if (token === null) {
    return [];
  }

  const match = token.toLowerCase();

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  let options: { label: string, replacement: string, ts: number }[] = [
    {
      label: 'Yesterday ' + toLongFormat(yesterday),
      replacement: formatReplacement(yesterday),
      ts: +yesterday
    },
    {
      label: 'Today ' + toLongFormat(today),
      replacement: formatReplacement(today),
      ts: +today
    },
    { 
      label: 'Tomorrow ' + toLongFormat(tomorrow),
      replacement: formatReplacement(tomorrow),
      ts: +tomorrow
    }
  ];

  if (token === '') {
    return options.map(opt => ({
      id: null,
      type: LinkType.DATE,
      label: opt.label,
      replacement: opt.replacement
    }));
  }

  options.push({
    label: `${toLongFormat(today)} ${durationDescription(today)}`,
    replacement: formatReplacement(today),
    ts: +today
  });

  const dayDate = new Date();
  const todayDay = today.getDay();
  const lastDate = new Date();

  days.forEach((day, idx) => {
      let dayDiff = idx - todayDay;
      if (dayDiff <= 0) {
        dayDiff += 7;
      }
      dayDate.setFullYear(today.getFullYear(), today.getMonth(), today.getDate());
      dayDate.setDate(today.getDate() + dayDiff);

      options.push({
        label: `next ${toLongFormat(dayDate)} ${durationDescription(dayDate)}`,
        replacement: formatReplacement(dayDate),
        ts: +dayDate
      });

      options.push({
        label: `${toLongFormat(dayDate)} ${durationDescription(dayDate)}`,
        replacement: formatReplacement(dayDate),
        ts: +dayDate
      });

      let lastDiff = idx - todayDay;
      if (lastDiff >= 0) {
        lastDiff -= 7;
      }
      lastDate.setFullYear(today.getFullYear(), today.getMonth(), today.getDate());
      lastDate.setDate(today.getDate() + lastDiff);

      options.push({
        label: `last ${toLongFormat(lastDate)} ${durationDescription(lastDate)}`,
        replacement: formatReplacement(lastDate),
        ts: +lastDate
      });

      options.push({
        label: `${toLongFormat(lastDate)} ${durationDescription(lastDate)}`,
        replacement: formatReplacement(lastDate),
        ts: +lastDate
      });
  });

  if (match === '+') {
    for (let i = 1; i <= 7; i++) {
      const plusDt = new Date();
      plusDt.setDate(plusDt.getDate() + i);

      options.push({
        label: `+${i} days (${toLongFormat(plusDt)})`,
        replacement: formatReplacement(plusDt),
        ts: +plusDt
      });
    }
  } else if (match === '-') {
    for (let i = -1; i >= -7; i--) {
      const negDt = new Date();
      negDt.setDate(negDt.getDate() + i);

      options.push({
        label: `${i} days (${toLongFormat(negDt)})`,
        replacement: formatReplacement(negDt),
        ts: +negDt
      });
    }
  } else if (match.startsWith('+') && /\+(\d{1,4}).*/.test(match)) {
    const reMatch = /\+(\d{1,4}).*/.exec(match);
    const plusDays = parseInt(reMatch[1], 10);
    const plusDt = new Date();
    plusDt.setDate(plusDt.getDate() + plusDays);
    options.push({
      label: `+${plusDays} days (${toLongFormat(plusDt)})`,
      replacement: formatReplacement(plusDt),
      ts: +plusDt
    });
  } else if (match.startsWith('-') && /\-(\d{1,4}).*/.test(match)) {
    const reMatch = /\-(\d{1,4}).*/.exec(match);
    const negDays = parseInt(reMatch[1], 10);
    const negDt = new Date();
    negDt.setDate(negDt.getDate() - negDays);
    options.push({
      label: `-${negDays} days (${toLongFormat(negDt)})`,
      replacement: formatReplacement(negDt),
      ts: +negDt
    });
  }

  options = options.filter(opt => opt.label.toLowerCase().startsWith(match));
  options.sort((a, b) => a.ts - b.ts);

  return options.map(opt => ({
    id: null,
    type: LinkType.DATE,
    label: opt.label,
    replacement: opt.replacement
  }));
}
