
import { AutocompleteItem } from "@/store";
import { AutocompleteApi, AutocompleteCollection, BaseItem, InternalAutocompleteSource } from "@algolia/autocomplete-core";
import { Options, Vue } from "vue-class-component";
import AppEditorItem from "./AppEditorItem.vue";

@Options({
  props: {
    autocomplete: Object,
    collections: Array,
    isOpen: Boolean,
    status: String,
    activeId: Number
  },
  components: {
    AppEditorItem
  }
})
export default class AppEditorPanel extends Vue {
  autocomplete: AutocompleteApi<AutocompleteItem, Event, MouseEvent, KeyboardEvent>;
  collections!: AutocompleteCollection<AutocompleteItem>[];
  isOpen!: boolean;
  status!: "idle" | "loading" | "stalled" | "error";
  activeId!: number;

  itemProps(item: AutocompleteItem, source: InternalAutocompleteSource<AutocompleteItem>): { 'aria-selected': boolean; } {
    const res = this.autocomplete.getItemProps({ item, source });
    return res;
  }
}
