import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["onclick", "onmousedown", "onmousemove"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppEditorUser = _resolveComponent("AppEditorUser")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "autoDiv",
    class: _normalizeClass(["autocomplete-item", { 'autocomplete-item-selected': _ctx.itemProps['aria-selected'] }]),
    onclick: _ctx.itemProps.onClick,
    onmousedown: _ctx.itemProps.onMouseDown,
    onmousemove: _ctx.itemProps.onMouseMove
  }, [
    _createVNode(_component_AppEditorUser, { hit: _ctx.item }, null, 8, ["hit"])
  ], 10, _hoisted_1))
}