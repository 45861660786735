import { DbId, MjCostCenter } from '@/dto/types'
import { pad } from '@/helpers/util';
import { BaseItem } from '@algolia/autocomplete-core';
import { createStore } from 'vuex'

export interface AutocompleteItem extends BaseItem {
  id: DbId | null;
  label: string;
  type: LinkType;
  replacement: string;
}

export const enum Priority {
  LOW,
  STANDARD,
  URGENT
}


export const enum LinkType {
  NONE,
  EXTERNAL,
  TASK,
  USER,
  PHOTO,
  DATE,
  COST_CENTER
}

export interface UserRecord {
  dbid: DbId,
  name: string,
  about: string,
  created?: Date
}

export interface Task {
  ccid: DbId;
  dbid: DbId,
  refid: string,
  localid: number,
  title: string,
  description: string,
  created?: Date,
  updated?: Date,
  priority: Priority
}

export interface PhotoRecord {
  dbid: DbId,
  alt: string,
  href: string,
  created?: Date
}

export interface StringLink {
  link?: {
    type: LinkType,
    href: string|DbId,
    ccid?: DbId
  },
  content: string
}

export interface HistoryItem {
  dbid: DbId,
  summary: string,
  priority: Priority,
  timestamp?: Date
}

export interface State {
  all: Map<DbId, MjCostCenter>,
  pinned: Set<DbId>,
  history: Map<DbId, Array<HistoryItem>>,
  users: Map<DbId, UserRecord>,
  tasks: Map<DbId, Task>,
  photos: Map<DbId, PhotoRecord>,
  loading: boolean
}

export default createStore({
  state: {
    all: new Map(),
    pinned: new Set(),
    history: new Map(),
    users: new Map(),
    tasks: new Map(),
    photos: new Map(),
    loading: true
  } as State,
  mutations: {
    loading(state, payload: boolean) {
      state.loading = payload;
    },
    load(state, payload: State) {
      for (let key of Object.keys(payload)) {
        state[key] = payload[key];
      }
    },
    updateTask(state, payload: { task: Task, costCenterId: DbId, priority: Priority }) {
      let dbid = payload.task.dbid;
      if (dbid < 0) {
        dbid = 0;
        // Find max dbid for all tasks
        state.tasks.forEach(tsk => dbid = tsk.dbid > dbid ? tsk.dbid : dbid);

        let localid = 0;
        // Find max localid for all tasks with the same ccid
        state.tasks.forEach(tsk => localid = (tsk.ccid === payload.task.ccid && tsk.localid > localid) ? tsk.localid : localid);

        payload.task.dbid = dbid + 1;
        payload.task.localid = localid + 1;
        payload.task.refid = `${payload.task.ccid}-T${pad(payload.task.localid)}`;
        payload.task.created = new Date();

        const hist = state.history.get(payload.costCenterId);
        if (!hist) {
          state.history.set(payload.costCenterId, []);
        }

        state.history.get(payload.costCenterId).unshift({
          dbid: -1,
          priority: payload.priority,
          timestamp: new Date(),
          summary: `@David created task @${payload.task.refid}: ${payload.task.title}`
        });

        state.tasks.set(payload.task.dbid, payload.task);
      } else {
        payload.task.updated = new Date();

        const hist = state.history.get(payload.costCenterId);
        if (!hist) {
          state.history.set(payload.costCenterId, []);
        }

        state.history.get(payload.costCenterId).unshift({
          dbid: -1,
          priority: payload.priority,
          timestamp: new Date(),
          summary: `@Bob edited task @${payload.task.refid}: ${payload.task.title}`
        });
      }
    },
    updateUsers(state, payload: Map<DbId, UserRecord>) {
      state.users = payload;
    },
    updateTasks(state, payload: Map<DbId, Task>) {
      state.tasks = payload;
    },
    updatePhotos(state, payload: Map<DbId, PhotoRecord>) {
      state.photos = payload;
    },
    updateCostCenters(state, payload: Map<DbId, MjCostCenter>) {
      state.all = payload;
    },
    addPinned(state, payload: DbId) {
      state.pinned.add(payload);
    },
    removePinned(state, payload: DbId) {
      state.pinned.delete(payload);
    },
    updateHistoryItems(state, payload: Map<DbId, Array<HistoryItem>>) {
      state.history = payload;
    }
  },
  actions: {
  },
  modules: {
  }
})
