import { DbId } from "@/dto/types";

const epochs: Array<[string, number]> = [
  ['year', 31536000],
  ['month', 2592000],
  ['day', 86400],
  ['hour', 3600],
  ['minute', 60],
  ['second', 1]
];

export const pad = (num: number): string => num < 10 ? `0${num}` : num.toString();

function getDuration(timeAgoInSeconds: number): { interval: number, epoch: string } {
  for (let [name, seconds] of epochs) {
      const interval = Math.floor(timeAgoInSeconds / seconds);
      if (interval >= 1) {
          return { interval, epoch: name };
      }
  }
  return { interval: 0, epoch: 'Just now' };
}

export function timeAgo(date: Date): string {
  const timeAgoInSeconds = Math.floor((+(new Date()) - (+date)) / 1000);
  const {interval, epoch} = getDuration(timeAgoInSeconds);
  if (interval === 0) {
    return epoch;
  } else {
    const suffix = interval === 1 ? '' : 's';
    return `${interval} ${epoch}${suffix} ago`;
  }
}

export function dtTmFormat(dt: Date) {
  return dt.toLocaleString();
}

export function formatTimestamp(dt: Date): string {
  return `${dt.getFullYear()}-${pad(dt.getMonth() + 1)}-${pad(dt.getDate())}T${pad(dt.getHours())}:${pad(dt.getMinutes())}:${pad(dt.getSeconds())}`;
}

export function fromTimestamp(dt: string): Date {
  const dtPart = dt.split('T')[0];
  const explodedDt = fromDbDt(dtPart);
  const tmParts = dt.split('T')[1].split(':');
  const parsed = tmParts.map(part => parseInt(part, 10));
  return new Date(explodedDt.vYear, explodedDt.vMonth - 1, explodedDt.vDate, parsed[0], parsed[1], parsed[2]);
}

export function toDbDtFormat(dt: Date): string {
  return `${dt.getFullYear()}-${pad(dt.getMonth() + 1)}-${pad(dt.getDate())}`;
}

export function fromDbDt(dbdt: string): { vDate: number, vMonth: number, vYear: number }|null {
  const parts = dbdt.split('-');

  if (parts.length === 3 && parts.every(part => /^(\d+)$/.test(part))) {
    const parsed = parts.map(part => parseInt(part, 10));
    const [vYear, vMonth, vDate] = parsed;
    if (vDate <= 31 && vDate >= 1 && vMonth <= 12 && vMonth >= 1 && vYear >= 1900 && vYear <= 2100) {
      return { vDate, vMonth, vYear };
    }
  }

  return null;
}

export function toDbDtFormatViaDecomposed(dt: { vDate: number, vMonth: number, vYear: number }): string {
  return `${dt.vYear}-${pad(dt.vMonth)}-${pad(dt.vDate)}`;
}

export function dtFormat(dt: Date) {
  return dt.toLocaleDateString();
}

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export function toAuDtFormat(dt: Date): string {
  return `${days[dt.getDay()]}-${pad(dt.getDate())}/${pad(dt.getMonth() + 1)}/${dt.getFullYear()}`;
}

export function memoizeId<T extends { dbid: DbId }>(fn: (dbid: DbId) => T): (dbid: DbId) => T {
  const cache: Map<DbId, T> = new Map();

  return (dbid: DbId) => {
    if (cache.has(dbid)) {
      return cache.get(dbid);
    } else {
      const result = fn(dbid);
      cache.set(dbid, result);
      return result;
    }
  }
}
