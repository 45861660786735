
import AppEditorPanel from "./AppEditorPanel.vue";
import { Options, Vue } from "vue-class-component";
import { AutocompleteApi, AutocompleteCollection, AutocompleteContext, AutocompleteSource, BaseItem, createAutocomplete, GetSources, GetSourcesParams } from '@algolia/autocomplete-core';

import { queryUsers } from './sources/users';
import { queryTasks } from './sources/tasks';
import { queryDates } from './sources/dates';
import { AutocompleteItem, State } from "@/store";
import getCaretCoordinates from 'textarea-caret';
import { Store } from "vuex";
import { queryCenters } from "./sources/centers";

@Options({
  props: {
    modelValue: Object,
    maxLength: Number,
    taclass: String,
    taid: String,
    wrapperClass: String,
    defaultTop: Number,
    allowNl: Boolean
  },
  emits: ['update:modelValue'],
  components: {
    AppEditorPanel
  }
})
export default class AppAutocompleteTextarea extends Vue {
  modelValue!: { text: string };
  maxLength!: number
  taclass!: string;
  taid!: string;
  wrapperClass!: string;
  defaultTop!: number;
  allowNl!: boolean;

  collections: AutocompleteCollection<BaseItem>[] = [];
  completion: string|null = null;
  context: AutocompleteContext = {};
  isOpen = false;
  query = '';
  activeItemId: number|null = null;
  status: 'idle' | 'loading' | 'stalled' | 'error' = 'idle';
  autocomplete: AutocompleteApi<AutocompleteItem, Event, MouseEvent, KeyboardEvent> = null;

  insert(text: string): void {
    const target = this.textarea();
    target.focus();
    setTimeout(() => {
      target.setSelectionRange(target.textLength, target.textLength);
      document.execCommand('insertText', false, text);
    }, 100);
  }

  textarea(): HTMLTextAreaElement {
    return this.$refs.appTextarea as HTMLTextAreaElement;
  }

  enterKey(event: KeyboardEvent): void {
    if (!this.allowNl) {
      event.preventDefault();
    }
  }

  emit(): void {
    this.$emit('update:modelValue', { text: this.textarea().value });
  }

  updateInput(): void {
    const ta = this.textarea();
    if (!ta) {
      return;
    }

    const props = this.autocomplete.getInputProps({ inputElement: ta as unknown as HTMLInputElement, maxLength: this.maxLength });
    for (let prop of Object.keys(props)) {
      if (prop.startsWith('aria') && props[prop]) {
        ta.setAttribute(prop, props[prop]);
      }
    }

    ta.value = props.value;
    ta.placeholder = props.placeholder;

    //ta.onfocus = props.onFocus;
    ta.onchange = props.onChange;
    // Also have to add it to to oninput because onchange does not
    // fire for every key press with textarea elements.
    ta.oninput = props.onChange;
    ta.onkeydown = props.onKeyDown;
    ta.onblur = props.onBlur;
    //ta.onclick = props.onClick;
  }

  updateContainer(): void {
    const cont = this.$refs.appContainer as HTMLElement;
    if (!cont) {
      return;
    }
    const props = this.autocomplete.getRootProps();
    for (let prop of Object.keys(props)) {
      if (prop.startsWith('aria') && props[prop]) {
        cont.setAttribute(prop, props[prop]);
      }
    }
  }

  updateTextareaValue(query: string, item: AutocompleteItem, setQuery: (text: string) => void): void {
    const ta = this.textarea();
    const end = ta.selectionEnd;

    const token = this.findToken(query, end);
    if (token === null) {
      return;
    }
    const start = end - token.length;
    ta.setRangeText(item.replacement + ' ', start, end, 'end');
    this.modelValue.text = ta.value;
    this.$emit('update:modelValue', { text: this.modelValue.text });
  }

  mounted() {
    this.autocomplete = createAutocomplete({
      defaultActiveItemId: 0,
      initialState: {
        isOpen: false
      },
      debug: false,
      id: this.taid,
      placeholder: 'Enter task description...',
      onStateChange: (props) => {
        this.collections = props.state.collections;
        this.completion = props.state.completion;
        this.context = props.state.context;
        this.isOpen = props.state.isOpen;

        if (!this.isOpen) {
          props.state.activeItemId = null;
        } else if (props.state.activeItemId === null) {
          props.state.activeItemId = 0;
        }

        this.query = props.state.query;
        this.activeItemId = props.state.activeItemId;
        this.status = props.state.status;

        this.updateContainer();
        this.updateInput();
      },
      getSources: (params) => 
      [
        this.createSource(params, 'user-source', queryUsers),
        this.createSource(params, 'center-source', queryCenters),
        this.createSource(params, 'task-source', queryTasks),
        this.createSource(params, 'date-source', queryDates)
      ]
    });
    this.autocomplete.refresh();
  }

  createSource(
    params: GetSourcesParams<AutocompleteItem>,
    sourceId: string,
    queryMethod: (token: string, store: Store<State>) => AutocompleteItem[]): AutocompleteSource<AutocompleteItem> {

    return {
      sourceId,
      getItems: ({ query }) => {
        const token = this.findToken(query, this.textarea().selectionEnd);
        return queryMethod(token, this.$store);
      },
      onSelect: ({ item, setQuery }) => this.updateTextareaValue(params.query, item, setQuery)
    };
  }

  getPanelTop(): number {
    if (this.textarea() === undefined) {
      return this.defaultTop;
    }

    const { top } = getCaretCoordinates(this.textarea(), this.textarea().selectionEnd);
    return Math.min((top || (this.defaultTop - 70)) + 70, this.defaultTop);
  }

  getPanelLeft(): number {
    if (this.textarea() === undefined) {
      return 0;
    }

    const ta = this.textarea();

    const { left } = getCaretCoordinates(ta, ta.selectionEnd);
    return Math.max(0, Math.min((left || 10) - 10, ta.clientWidth - 450));
  }

  /**
   * Looks backwards from the selection, returning all characters
   * between the last at symbol and the selection or null if a newline, tab or
   * start of text box were found first.
   */
  findToken(query: string, selectionEnd: number): string|null {
    let idx = selectionEnd - 1;
    const result: Array<string> = [];

    while (idx >= 0 && idx < query.length) {
      const ch = query.charAt(idx);
      if (ch === '@') {
        return result.reverse().join('');
      } else if (ch === '\n' || ch === '\t') {
        return null;
      }
      result.push(ch);
      idx--;
    }

    return null;
  }
}
