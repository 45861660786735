
import AppBadge from "@/components/AppBadge.vue";
import AppTime from "@/components/AppTime.vue";
import { parseAuDate } from "@/components/autocomplete/sources/dates";
import StringLinkInfo from "@/components/StringLinkInfo.vue";
import StringLinkList from "@/components/StringLinkList.vue";
import { parsePreview } from "@/components/taskpreview/parsePreview";
import { dtTmFormat, fromDbDt, timeAgo, toAuDtFormat, toDbDtFormat, toDbDtFormatViaDecomposed } from "@/helpers/util";
import { HistoryItem, LinkType, StringLink, Task } from "@/store";
import { Options, Vue } from "vue-class-component";

type Day = {
  m: string,
  d: number,
  faded: boolean,
  mapKey: string,
  description: string
};

type Row = {
    cols: Day[]
};

@Options({
  props: {

  },
  components: {
    StringLinkList,
    StringLinkInfo,
    AppBadge,
    AppTime
  }
})
export default class Calendar extends Vue {
  days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  currentInfo: StringLink = null;
  currentIdx: number = null;
  currentDay: Day = null;
  currentRowIdx: number = null;
  currentList: 'tasks'|'history' = null;
  activeTab: 'tasks'|'mentions' = 'tasks';

  mounted() {
    this.$watch(() => this.$route.params.date, () => {
      this.currentInfo = null;
      this.currentRowIdx = null;
      this.currentDay = null;
      this.currentList = null;
    });
  }

  get prevMonth(): string {
    const cd = new Date(this.startDate);
    cd.setMonth(cd.getMonth() - 1);
    return `/cal/${cd.getFullYear()}/${cd.getMonth() + 1}/0`;
  }

  get nextMonth(): string {
        const cd = new Date(this.startDate);
    cd.setMonth(cd.getMonth() + 1);
    return `/cal/${cd.getFullYear()}/${cd.getMonth() + 1}/0`;
  }

  linkFromDbDt(dbdt: string): string {
    const { vYear, vMonth, vDate } = fromDbDt(dbdt);
    return `${vYear}/${vMonth}/${vDate}`;
  }

  isUrlDate(dbdt: string): boolean {
    if (!parseInt(this.$route.params.date as string, 10)) {
      return false;
    }

    const { vYear, vMonth, vDate } = fromDbDt(dbdt);
    const urlDate = this.startDate;

    return (urlDate.getFullYear() === vYear && urlDate.getMonth() + 1 === vMonth && urlDate.getDate() === vDate);
  }

  timeAgo(dt: Date): string {
    return timeAgo(dt);
  }

  dtTmFormat(date: Date): string {
    return dtTmFormat(date);
  }

  showAll(item: Day, rowIdx: number, tab: typeof Calendar.prototype.activeTab): void {
    this.currentDay = item;
    this.currentRowIdx = rowIdx;
    this.currentInfo = null;
    this.currentIdx = null;
    this.activeTab = tab;
  }

  showInfo(info: { event: MouseEvent, item: StringLink }, idx: number, list: typeof Calendar.prototype.currentList): void {
    this.currentInfo = info.item;
    this.currentIdx = idx;
    this.currentList = list;
  }

  getTaskCount(dbdt: string): number|null {
    return this.dateItems.get(dbdt)?.tasks.size;
  }

  getHistoryCount(dbdt: string): number|null {
    return this.dateItems.get(dbdt)?.history.size;
  }

  get dateItems():
    Map<string,
      {
        all: Set<HistoryItem|Task>,
        tasks: Set<Task>,
        history: Set<HistoryItem>
      }> {

    const map = new Map() as typeof Calendar.prototype.dateItems;
    const tasks = Array.from(this.$store.state.tasks.values());
    const history = Array.from(this.$store.state.history.values()).flat();

    const strFinder =
      (tsk: Task) =>
      (str: string|StringLink): void => {
        if (typeof str !== 'string') {
          const strlink = str as StringLink;
          if (strlink.link.type === LinkType.DATE) {
            const foundDbDt = toDbDtFormatViaDecomposed(parseAuDate(strlink.link.href as string, 'ymd'));
            let current = map.get(foundDbDt);

            if (!current) {
              current = { all: new Set(), tasks: new Set(), history: new Set() };
              map.set(foundDbDt, current);
            }

            if (current.all.has(tsk)) {
              // Do nothing
            } else {
              current.all.add(tsk);
              current.tasks.add(tsk);
            }
          }
        }
      };

    for (let task of tasks) {
      task.title.split('\n')
        .map(t => this.processLine(t))
        .flat()
        .forEach(strFinder(task));
      
      task.description.split('\n')
          .map(d => this.processLine(d))
          .flat()
          .some(strFinder(task));
    }

    for (let hist of history) {
      const dbdt = toDbDtFormat(hist.timestamp);
      let current = map.get(dbdt);

      if (!current) {
        current = { all: new Set(), tasks: new Set(), history: new Set() };
        map.set(dbdt, current);
      }

      current.all.add(hist);
      current.history.add(hist);
    }

    return map;
  }

  history(dbdt: string): HistoryItem[] {
    const res = [];
    Array.from(this.$store.state.history.values()).forEach(items => {
      res.push(...items.filter(item => toDbDtFormat(item.timestamp) === dbdt));
    });
    return res;
  }

  processLine(line: string) {
    return parsePreview(line, this.$store);
  }

  get startDate(): Date {
    return new Date(
      parseInt(this.$route.params.year as string, 10),
      parseInt(this.$route.params.month as string, 10) - 1,
      parseInt(this.$route.params.date as string, 10) || 1);
  }

  get dates(): Array<Row> {
    const months = this.months;
    const startDate = this.startDate;

    const monthDate = new Date(startDate);
    monthDate.setDate(1);

    const underDate = new Date(monthDate);

    const overDate = new Date(monthDate);
    overDate.setMonth(overDate.getMonth() + 1);
    overDate.setDate(overDate.getDate() - 1);

    const res = [] as Array<Day>;

    while (underDate.getDay() !== 0) {
      underDate.setDate(underDate.getDate() - 1);
    }

    while (overDate.getDay() !== 6) {
      overDate.setDate(overDate.getDate() + 1);
    }

    do {
      res.push({
        m: months[underDate.getMonth()],
        d: underDate.getDate(),
        faded: underDate.getMonth() !== monthDate.getMonth(),
        mapKey: toDbDtFormat(underDate),
        description: toAuDtFormat(underDate)
      });
      underDate.setDate(underDate.getDate() + 1);
    } while (underDate.getMonth() !== overDate.getMonth() || underDate.getDate() !== overDate.getDate());

    res.push({
      m: months[underDate.getMonth()],
      d: underDate.getDate(),
      faded: underDate.getMonth() !== monthDate.getMonth(),
      mapKey: toDbDtFormat(underDate),
      description: toAuDtFormat(underDate)
    });

    const rowCount = res.length / 7;
    const rows = Array(rowCount).fill(null).map(_ => ({ cols: [] })) as typeof Calendar.prototype.dates;

    for (let i = 0; i < res.length; i++) {
      const rowIdx = Math.floor(i / 7);
      rows[rowIdx].cols.push(res[i]);
    }

    return rows;
  }

  get itemMap(): Map<string, string[]> {
    const result: Array<[string, string]> = [];

    for (let item of this.$store.state.tasks.values()) {
      result.push([toDbDtFormat(item.created), '@' + item.dbid]);
    }

    const map = new Map<string, string[]>();

    result.forEach(pair => {
      const existing = map.get(pair[0]);
      if (existing) {
        existing.push(pair[1]);
      } else {
        map.set(pair[0], [pair[1]]);
      }
    });

    return map;
  }

  parse(text: string): (StringLink|string)[] {
    return parsePreview(text, this.$store);
  }
}
