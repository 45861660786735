import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CostCenterPinnedList = _resolveComponent("CostCenterPinnedList")!
  const _component_o_tab_item = _resolveComponent("o-tab-item")!
  const _component_CostCenterAllList = _resolveComponent("CostCenterAllList")!
  const _component_o_tabs = _resolveComponent("o-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_o_tabs, {
        expanded: false,
        size: "large",
        position: "centered",
        animated: false,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeTab($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_o_tab_item, {
            value: 0,
            label: "Pinned"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CostCenterPinnedList, { ref: "pinList" }, null, 512)
            ]),
            _: 1
          }),
          _createVNode(_component_o_tab_item, {
            value: 1,
            label: "All"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CostCenterAllList)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}