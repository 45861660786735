
import { DbId, MjCostCenter } from '@/dto/types';
import { dtTmFormat, timeAgo } from '@/helpers/util';
import { HistoryItem, LinkType, Priority, StringLink, Task } from '@/store';
import { Options, Vue } from 'vue-class-component';
import AppBadge from './AppBadge.vue';
import AppDateTime from './AppDateTime.vue';
import StringLinkInfo from './StringLinkInfo.vue';
import StringLinkList from './StringLinkList.vue';
import { parsePreview } from './taskpreview/parsePreview';
import { findMentions } from './../helpers/mentions';

@Options({
  props: {
    center: Object
  },
  components: {
    StringLinkList,
    StringLinkInfo,
    AppBadge,
    AppDateTime
  },
  emits: []
})
export default class QuickHistoryList extends Vue {
  center!: MjCostCenter;

  activeTab: 'tasks'|'mentions'|'actions' = 'tasks';
  currentInfo: StringLink = null;
  currentHistoryIdx: number = null;
  currentMentionIdx: number = null;
  currentTaskIdx: number = null;

  get tasks(): Array<Task> {
    const res: Array<Task> = [];
    for (let tsk of this.$store.state.tasks.values()) {
      if (tsk.ccid === this.center.dbid) {
        res.push(tsk);
      }
    }
    return res;
  }

  get historyItems(): Array<HistoryItem>|null {
    return this.$store.state.history.get(this.center.dbid);
  }

  get mentions(): Array<Task> {
    return Array.from(findMentions(this.$store, LinkType.COST_CENTER, this.center.dbid).values());
  }

  showInfo(info: { event: MouseEvent, item: StringLink }, historyIdx: DbId): void {
    this.currentInfo = info.item;
    this.currentHistoryIdx = historyIdx;
    this.currentMentionIdx = null;
    this.currentTaskIdx = null;
  }

  showMention(info: { event: MouseEvent, item: StringLink }, historyIdx: DbId): void {
    this.currentInfo = info.item;
    this.currentHistoryIdx = null;
    this.currentMentionIdx = historyIdx;
    this.currentTaskIdx = null;
  }

  showTask(info: { event: MouseEvent, item: StringLink }, historyIdx: DbId): void {
    this.currentInfo = info.item;
    this.currentHistoryIdx = null;
    this.currentTaskIdx = historyIdx;
    this.currentMentionIdx = null;
  }

  processLine(line: string) {
    return parsePreview(line, this.$store);
  }
}
