import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-898259f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "list" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CostCenterSummary = _resolveComponent("CostCenterSummary")!
  const _component_QuickHistoryList = _resolveComponent("QuickHistoryList")!
  const _component_o_collapse = _resolveComponent("o-collapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storePinned, (cc) => {
        return (_openBlock(), _createElementBlock("li", {
          key: cc.dbid,
          class: _normalizeClass([{ 'removing': _ctx.pinRemoved.has(cc.dbid) }, "cc"])
        }, [
          _createVNode(_component_CostCenterSummary, {
            center: cc,
            pinned: _ctx.isPinned(cc.dbid),
            removing: _ctx.pinRemoved.has(cc.dbid),
            onPinToggled: _cache[0] || (_cache[0] = ($event: any) => (_ctx.togglePin($event.dbid, $event.pinState))),
            onShowMore: _cache[1] || (_cache[1] = ($event: any) => (_ctx.expand($event)))
          }, null, 8, ["center", "pinned", "removing"]),
          _createVNode(_component_o_collapse, {
            open: _ctx.openDbid === cc.dbid,
            onOpen: ($event: any) => (_ctx.openDbid = cc.dbid)
          }, {
            trigger: _withCtx(() => []),
            default: _withCtx(() => [
              (_ctx.openDbid === cc.dbid)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_QuickHistoryList, { center: cc }, null, 8, ["center"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["open", "onOpen"])
        ], 2))
      }), 128))
    ])
  ]))
}