import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Task from '../views/Task.vue'
import Calendar from '../views/Calendar.vue'
import Recent from '../views/Recent.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/cc/:ccid/task/:id',
    name: 'Task',
    component: Task
  },
  {
    path: '/cal/:year/:month/:date',
    name: 'Calendar',
    component: Calendar
  },
  {
    path: '/recent',
    name: 'Recent',
    component: Recent
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
