
import AppEditor from "@/components/autocomplete/AppEditor.vue";
import AppTaskPreview from "@/components/taskpreview/AppTaskPreview.vue";
import { DbId } from "@/dto/types";
import { Priority, Task } from "@/store";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    AppEditor,
    AppTaskPreview
  }
})
export default class TaskEditor extends Vue {
  activeTab = 0;
  ntask: Task = {
    ccid: -1,
    dbid: -1,
    localid: -1,
    refid: '',
    title: '',
    description: '',
    created: new Date(),
    priority: Priority.STANDARD
  };

  save(): void {
    this.$store.commit('updateTask', {
      costCenterId: this.costCenterId,
      task: this.task,
      priority: this.task.priority
    });
  }

  get task(): Task {
    const tid = parseInt(this.$route.params.id as string, 10);
    this.ntask.ccid = parseInt(this.$route.params.ccid as string, 10);

    return tid < 0
      ? this.ntask
      : this.$store.state.tasks.get(tid);
  }

  set task(tsk: Task) {
    this.save();
  }

  get costCenterId(): DbId {
    return parseInt(this.$route.params.ccid as string, 10);
  }

}
