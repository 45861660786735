
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    color: String,
    size: Number,
    modelValue: Boolean,
    title: String,
    icon: String
  },
  emits: [
    'update:modelValue'
  ]
})
export default class IconCheckbox extends Vue {
  color!: string;
  size!: number;
  modelValue!: boolean;
  title!: String;
  icon!: String;

  update($event: Event) {
    const checked = (($event.target) as HTMLInputElement).checked;
    this.$emit('update:modelValue', checked);
  }

  get tooltip(): string {
    const enabled = this.modelValue ? 'enabled' : 'not enabled';
    return `${this.title} (${enabled})`;
  }
}
