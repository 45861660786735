
import { Options, Vue } from 'vue-class-component';
import CostCenterPinnedList from '@/components/CostCenterPinnedList.vue';
import CostCenterAllList from '@/components/CostCenterAllList.vue';

@Options({
  components: {
    CostCenterPinnedList,
    CostCenterAllList
  },
})
export default class Home extends Vue {
  activeTab = 0;

  changeTab(tab: number): void {
    (this.$refs.pinList as CostCenterPinnedList).refresh();
    this.activeTab = tab;
  }
}
