import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fd4d943"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "item-highlight"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (str, idx) => {
    return (_openBlock(), _createElementBlock("span", { key: idx }, [
      (typeof str === 'string')
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(str), 1))
        : (_openBlock(), _createElementBlock("a", {
            key: 1,
            class: "tt-link",
            href: "#",
            onClick: _withModifiers(($event: any) => (_ctx.doLinkClick($event, str)), ["prevent"])
          }, [
            (_ctx.isHighlight(str))
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(str.content), 1))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(str.content), 1)
                ], 64))
          ], 8, _hoisted_2))
    ]))
  }), 128))
}