
import { Options, Vue } from "vue-class-component";
import { Priority, Task } from "@/store";
import { DbId } from "@/dto/types";
import AppAutocompleteTextarea from "./AppAutocompleteTextarea.vue";

@Options({
  props: {
    modelValue: Object,
    costCenterId: Number
  },
  emits: ['update:modelValue'],
  components: {
    AppAutocompleteTextarea
  }
})
export default class AppEditor extends Vue {
  costCenterId!: DbId;
  modelValue!: Task;

  save() {
    this.$emit('update:modelValue', this.modelValue);
    this.$router.push('/');
  }

  insert(text: string): void {
    const autoDescription = this.$refs.autoDescription as AppAutocompleteTextarea;
    autoDescription.insert(text);
  }

  get title(): { text: string } {
    return { text: this.modelValue.title };
  }

  set title(val: { text: string }) {
    this.modelValue.title = val.text;
  }

  get description(): { text: string } {
    return { text: this.modelValue.description };
  }

  set description(val: { text: string }) {
    this.modelValue.description = val.text;
  }

  get urgent(): boolean {
    return this.modelValue.priority === Priority.URGENT;
  }

  set urgent(val: boolean) {
    this.modelValue.priority = val ? Priority.URGENT : Priority.STANDARD;
  }
}
