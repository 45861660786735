
import { dtTmFormat, timeAgo } from "@/helpers/util";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    dt: Date
  }
})
export default class AppDateTime extends Vue {
  dt!: Date;

  get timeAgo(): string {
    return timeAgo(this.dt);
  }

  get dtTmFormat(): string {
    return dtTmFormat(this.dt);
  }
}
