import { parsePreview } from "@/components/taskpreview/parsePreview";
import { LinkType, State, StringLink, Task } from "@/store";
import { Store } from "vuex";

/**
 * Find mentions of a cost center, task, etc in task list.
 */
export function findMentions(store: Store<State>, type: LinkType, link: string|number): Set<Task> {
  const res: Set<Task> = new Set();
  const strFinder =
      (tsk: Task) =>
      (str: string|StringLink): void => {
        if (typeof str !== 'string') {
          const strlink = str as StringLink;

          if (strlink.link.type === type && strlink.link.href === link) {
            res.add(tsk);
          }
        }
      };

  for (let task of store.state.tasks.values()) {
    task.title.split('\n')
      .map(t => parsePreview(t, store))
      .flat()
      .forEach(strFinder(task));

    task.description.split('\n')
        .map(d => parsePreview(d, store))
        .flat()
        .some(strFinder(task));
  }

  return res;
}
