import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { Tabs, Collapse, Tooltip, Switch, Loading } from '@oruga-ui/oruga-next'
import '@oruga-ui/oruga-next/dist/oruga-full.css'

createApp(App)
  .use(Loading)
  .use(Switch)
  .use(Tooltip)
  .use(Collapse)
  .use(Tabs)
  .use(store)
  .use(router)
  .mount('#app');
