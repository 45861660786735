
import { dtTmFormat, timeAgo } from "@/helpers/util";
import { Priority, StringLink, Task } from "@/store";
import { Options, Vue } from "vue-class-component";
import StringLinkList from "../StringLinkList.vue";
import { parsePreview } from "../taskpreview/parsePreview";

@Options({
  props: {
    item: Object,
    highlight: Array,
    showRefsInline: {
      type: Boolean,
      default: false
    },
    showEdit: {
      type: Boolean,
      default: true
    }
  },
  components: {
    StringLinkList
  },
  emits: ['itemClick']
})
export default class InfoTask extends Vue {
  item!: Task;
  highlight!: Array<string>;
  showRefsInline!: boolean;
  showEdit = true;

  currentInfo: StringLink = null;
  currentIdx: number = null;

  beforeCreate() {
    // We have to do this to avoid circular reference
    this.$options.components.StringLinkInfo = require("../StringLinkInfo.vue").default;
  }

  doSubItemClick(info: { item: StringLink, event: MouseEvent }, idx: number) {
    if (!this.showRefsInline) {
      this.$emit('itemClick', info);
    } else {
      this.currentInfo = info.item;
      this.currentIdx = idx;
    }
  }

  get priorityDescription(): string {
    return this.item.priority === Priority.URGENT ? 'Urgent' : 'Standard';
  }

  split(str: string): string[] {
    return str.split('\n');
  }

  processLine(line: string) {
    return parsePreview(line, this.$store);
  }

  dtTmFormat(date: Date): string {
    return dtTmFormat(date);
  }

  timeAgo(date: Date): string {
    return timeAgo(date);
  }
}
