
import { Priority } from "@/store";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    priority: Number
  }
})
export default class AppBadge extends Vue {
  priority: Priority;

  get badge(): { clazz: string, icon: string, title: string } {
    switch (this.priority) {
      case Priority.URGENT:
        return { clazz: 'app-priority-urgent-bg', icon: 'error_outline', title: 'Urgent priority' };
      case Priority.STANDARD:
        return { clazz: 'app-priority-standard-bg', icon: 'circle_notifications', title: 'Standard priority' };
      case Priority.LOW:
        return { clazz: 'app-priority-success-bg', icon: 'check_circle', title: 'Completed (low priority)' };
    }
  }
}
