import { LinkType, State, StringLink } from "@/store";
import { Store } from "vuex";

export function getPhotoLink(token: string, store: Store<State>): StringLink|null {
  if (token.length === 0 || !/^photo\-\d+$/.test(token)) {
    return null;
  }

  const match = parseInt(token.substr('photo-'.length), 10);
  const photo = store.state.photos.get(match);

  if (photo) {
    return {
      content: '@' + token,
      link: {
        type: LinkType.PHOTO,
        href: match
      }
    };
  }

  return null;
}
